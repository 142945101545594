<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    class="pay-dialog"
    content-class="height-mid"
    overlay-color="black"
    overlay-opacity="0.8"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="pa-3"
      rounded
      style="width: 100%; border-radius: 10px 10px 0 0 !important;"
      color="#353535"
    >
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
        dense
        class="px-4"
      >
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-body-1 white--text pl-0">
          <span>{{ t('pay_dialog.get_gremium_video') }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="close-btn"
          icon
          small
          style="background-color: #3f3e3e"
          @click="$emit('update:is-open', false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text class="px-2">
        <p class="ma-0 py-3 mb-3 text-center text-start">
          {{ t('pay_video.card_information') }}
        </p>

        <div v-if="hasUser">
          <v-select
            v-model="selectedCard"
            :placeholder="t('payment_methods.payment_method')"
            :items="paymentMethods"
            item-text="last_4"
            item-value="id"
            class="mb-2"
            outlined
            rounded
            hide-details
            :rules="[validators.required]"
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              <span class="text-capitalize mr-1">{{ data.item.brand }}</span>
              <span v-if="data.item.last_4">ending {{ data.item.last_4 }}</span>
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              <span class="text-capitalize mr-1">{{ data.item.brand }}</span>
              <span v-if="data.item.last_4">ending {{ data.item.last_4 }}</span>
            </template>
          </v-select>
        </div>

        <v-form
          ref="form"
        >
          <div v-if="!hasUser || ( hasUser && selectedCard === 0)">
            <v-text-field
              v-model="formData.card_holder"
              outlined
              rounded
              :placeholder="t('payment_methods.name_card')"
              :rules="[validators.required]"
              class="mb-2"
              hide-details
            />

            <v-text-field
              v-model="formData.card_number"
              v-mask="'#### #### #### ####'"
              outlined
              rounded
              :placeholder="t('payment_methods.card_number')"
              :rules="validators.rules"
              class="mb-2"
              hide-details
              @keyup="identifyCard"
            >
              <template v-slot:append>
                <div style="width: 100%">
                  <v-icon
                    v-if="cardFilter === '*'"
                    color="primary"
                    size="30"
                  >
                    {{ icons.mdiCreditCardOutline }}
                  </v-icon>

                  <v-img
                    v-else
                    height="30"
                    width="30"
                    :src="require(`@/assets/images/cards/${cardFilter}.png`)"
                  />
                </div>
              </template>
            </v-text-field>

            <div class="d-flex justify-space-between">
              <v-text-field
                v-model="formData.card_date"
                v-mask="'##/##'"
                :label="t('payment_methods.expiration_date')"
                :placeholder="t('payment_methods.card_date')"
                class="mb-2 pr-2"
                :rules="[validators.required, validators.isValidDateRule]"
                outlined
                rounded
                hide-details
                :prepend-inner-icon="icons.mdiCalendar"
              />

              <v-text-field
                v-model="formData.cvv"
                outlined
                rounded
                :placeholder="t('payment_methods.cvv')"
                :rules="[validators.required, validators.integerValidator]"
                class="mb-2 pl-2"
                hide-details
              />
            </div>

            <v-text-field
              v-if="!userData"
              v-model="formData.email"
              outlined
              rounded
              :placeholder="t('users.email')"
              :rules="[validators.required, validators.emailValidator]"
              class="mb-2"
              hide-details
            />

            <v-autocomplete
              v-model="formData.country"
              :items="phoneCodeOptions"
              item-value="value"
              item-text="name"
              :placeholder="t('users.country')"
              outlined
              rounded
              :rules="[validators.required]"
              class="mb-2"
              hide-details
            >
              <template v-slot:item="{ item }">
                <img
                  :src="item.image"
                  :alt="item.name"
                  width="25"
                  height="auto"
                  class="mr-2"
                />
                {{ item.name }}
              </template>
            </v-autocomplete>

            <v-text-field
              v-model="formData.zip_code"
              outlined
              rounded
              :placeholder="t('payment_methods.zip_code')"
              :rules="[validators.required]"
              class="mb-2"
              hide-details
            />

            <v-checkbox
              v-if="hasUser"
              v-model="saveCreditCard"
              true-value="Y"
              false-value="N"
              hide-details
              class="mb-3"
              color="secondary"
            >
              <template v-slot:label>
                <div>
                  {{ t('pay_video.save_credit_card') }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex flex-column justify-center pt-0 pb-3 px-2">
        <v-btn
          block
          size="x-large"
          color="secondary"
          type="submit"
          class="mt-6 text-capitalize"
          style="height: 50px !important"
          @click="onPay"
        >
          {{ t('pay_video.pay') }} {{ formatCurrency(total) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ref, computed, watch, onBeforeMount,
} from '@vue/composition-api'
import {
  mdiPlus, mdiDeleteOutline, mdiClose, mdiMenuDown, mdiCreditCardOutline,
} from '@mdi/js'
import {
  required,
  minLengthValidator,
  integerValidator,
  emailValidator,
  lengthBetweenValidator,
} from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency } from '@core/utils'
import usePhone from '@core/utils/usePhone'
import CardValidator from 'card-validator'

import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'

export default {
  name: 'CheckoutPay',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    taxes: {
      type: Number,
      default: 0,
      required: true,
    },
    subtotal: {
      type: Number,
      default: 0,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasUser: {
      type: Boolean,
      required: true,
      default: false,
    },
    userData: {
      type: [Object, Boolean],
      required: true,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { phoneCodeOptions } = usePhone()

    const form = ref(null)
    const formData = ref({
      card_number: null,
      card_date: null,
      exp_month: null,
      exp_year: null,
      cvv: null,
      email: null,
      first_name: null,
      last_name: null,
    })
    const saveCreditCard = ref(0)
    const cardData = ref({
      cvc: null,
      expiry: null,
      number: null,
      name: null,
    })
    const selectedCard = ref(0)
    const cardFilter = ref('*')
    const result = computed(() => CardValidator.number(formData.value.card_number))
    const rules = [value => !!value || 'Required', () => result.value.isValid || 'Invalid Card Number']
    const iso = ref(null)
    const mountedIso = ref(false)
    const menu = ref(false)
    const modal = ref(false)
    const date = ref(
      new Date().toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const currentDate = ref(new Date())
    const currentYear = ref(currentDate.value.getFullYear() - 2000)
    const currentMonth = ref(currentDate.value.getMonth() + 1)

    const logDataComputed = computed(() => props.logData)
    const isPhoneVisible = ref(false)

    const validateExpDate = (mm, yy) => {
      const expirationYear = parseInt(yy, 10)
      const expirationMonth = parseInt(mm, 10)

      if (expirationYear < currentYear.value) {
        return false
      }

      if (expirationYear === currentYear.value && expirationMonth < currentMonth.value) {
        return false
      }

      return true
    }

    const isValidDateRule = value => {
      if (value === null || value === undefined || value === '') {
        return true
      }

      const regex = /^((0[1-9])|(1[0-2]))\/(\d{2})$/
      if (!regex.test(value)) {
        return false
      }

      const dateParts = value.split('/')
      const expirationYear = parseInt(dateParts[1], 10)
      const expirationMonth = parseInt(dateParts[0], 10)

      return validateExpDate(expirationMonth, expirationYear)
    }

    const computedIsOpen = computed(() => props.isOpen)
    const computedPaymentMethods = computed(() => props.paymentMethods)

    const capitalize = text => text
      .split(' ')
      .map(e => `${e.at(0).toUpperCase()}${e.substring(1, e.length)}`)
      .join(' ')

    const cardNumberRule = value => {
      if (!value) return 'Required'
      if (!result.value.isValid) return 'Invalid Card Number'

      return true
    }

    const identifyCard = () => {
      const niceType = result.value?.card?.niceType
      let filter = '*'
      let filterCard = '*'
      if (niceType) {
        const type = niceType.toLowerCase().replace(/\s/g, '')
        filter = `#${type}`
        filterCard = `${type}`
      }

      cardFilter.value = filterCard
      if (iso.value) iso.value.arrange({ filter })
    }

    const onPay = () => {
      let cardInfo = {
        payment_method_id: null,
        save_card: null,
        name: null,
        number: null,
        exp_month: null,
        exp_year: null,
        cvc: null,
        country: null,
        zip_code: null,
      }
      if (!selectedCard.value && form.value.validate()) {
        const [mounth, year] = formData.value.card_date.split('/')
        let fullYear = year

        if (year.length === 2) fullYear = `20${year}`
        cardInfo = {
          payment_method_id: null,
          save_card: props.hasUser ? saveCreditCard.value : 'N',
          name: capitalize(formData.value.card_holder),
          number: formData.value.card_number,
          exp_month: parseInt(mounth, 10),
          exp_year: parseInt(fullYear, 10),
          cvc: formData.value.cvv,
          country: formData.value.country,
          zip_code: formData.value.zip_code,
          email: formData.value.email,
        }
        emit('save', cardInfo)
      } else if (saveCreditCard.value !== 0) {
        cardInfo = {
          payment_method_id: selectedCard.value,
          save_card: null,
          name: null,
          number: null,
          exp_month: null,
          exp_year: null,
          cvc: null,
          country: null,
          zip_code: null,
        }
        emit('save', cardInfo)
      }
    }

    watch([selectedCard], () => {
      if (selectedCard.value !== 0) form.value.reset()

      formData.value.save_credit_card = 'N'
      saveCreditCard.value = 'N'
    })

    watch([computedIsOpen], () => {
      if (computedIsOpen.value) {
        if (computedPaymentMethods.value.length > 1) {
          selectedCard.value = computedPaymentMethods.value.at(1).id
        } else {
          selectedCard.value = 0
        }
      } else {
        formData.value = {
          card_number: null,
          card_date: null,
          exp_month: null,
          exp_year: null,
          cvv: null,
          name: null,
          email: null,
        }
        form.value.resetValidation()
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    return {
      // data
      form,
      formData,
      cardData,
      cardFilter,
      cardNumberRule,
      selectedCard,
      mountedIso,
      menu,
      modal,
      date,
      saveCreditCard,

      isPhoneVisible,
      logDataComputed,
      phoneCodeOptions,

      // computed
      computedIsOpen,
      computedPaymentMethods,

      // methods
      onPay,
      formatCurrency,
      identifyCard,
      validateExpDate,

      // validations
      required,

      // i18n
      t,

      // validators
      validators: {
        required,
        minLengthValidator,
        integerValidator,
        emailValidator,
        cardNumberRule,
        isValidDateRule,
        lengthBetweenValidator,
        rules,
      },

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiClose,
        mdiMenuDown,
        mdiCreditCardOutline,
      },
    }
  },
}
</script>
<style lang="scss">
.border-grey {
  border: 1px solid #ffffff8f;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.height-mid {
  border-radius: 0;
  margin: auto auto 0;
  height: auto;
  width: 28rem !important;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  margin: 0 !important;
  margin-bottom: -2px !important;
  // left: 50%;
  // transform: translateX(-50%);
}

@media only screen and (max-width: 600px) {
  .height-mid {
    width: 100% !important;
  }
}

.b-top {
  border-radius: 10px 10px 0 0 !important;
}

.close-btn {
  background-color: #353535;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-block: auto;
}

.terms-and-policies {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.v-slide-group__next, .v-slide-group__prev {
  min-width: 30px !important;
}

</style>
