/* eslint-disable no-confusing-arrow */
/* eslint-disable new-cap */
import { ref, computed } from '@vue/composition-api'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useVideoData() {
  const { encrypt, decrypt } = useCryptoJs()

  const videoData = ref({
    facility: null,
    video: null,
    match: null,
    price: null,
    paymentMethodsParams: null,
    landingParams: null,
  })

  const updateVideoData = async Video => {
    const encryptVideoData = encrypt(Video)
    await localStorage.setItem('videoData', JSON.stringify(encryptVideoData))
  }

  const currentVideoData = computed(() => {
    const encryptVideoData = localStorage.getItem('videoData')
    if (encryptVideoData) {
      const decryptVideoData = decrypt(JSON.parse(encryptVideoData))

      return JSON.parse(decryptVideoData)
    }

    return encryptVideoData
  })

  const clearVideoData = async () => {
    Object.keys(videoData.value).forEach(i => { videoData.value[i] = null })
    videoData.value.facility = null
    videoData.value.video = null
    await localStorage.removeItem('videoData')
  }

  return {
    // data
    videoData,

    // computed
    currentVideoData,

    // methods
    updateVideoData,
    clearVideoData,
  }
}
