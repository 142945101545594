<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold white--text mb-2">
          {{ t('pay_dialog.checkout') }}
        </p>
        <div class="ma-0 pt-4 text-center d-flex flex-column">
          <span>{{ videoData.video ? formatVideoDay(`${videoData.video.video_date} ${videoData.video.start_time}`) : '' }}</span>
          <span>{{ videoData.video ? `${formatVideoMonth(`${videoData.video.video_date} ${videoData.video.start_time}`)} • ${videoData.video.facility_name} • ${videoData.video.field_name}` : '' }}</span>
        </div>
      </v-card-text>

      <v-card-text class="">
        <v-carousel
          class="rounded-lg"
          height="205px"
          width="auto"
          hide-delimiters
          show-arrows-on-hover
          cycle
          :show-arrows="false"
        >
          <div>
            <v-carousel-item
              v-if="videoData.video && videoData.video.thumbnail && !videoData.video.thumbnail.includes('no-thumb')"
              :src="videoData.video.thumbnail"
              eager
              class="cursor-pointer bg-grey"
            >
            </v-carousel-item>

            <div v-else-if="videoData.video && videoData.video.thumbnails && videoData.video.thumbnails.some(e => !e.includes('no-thumb'))">
              <v-carousel-item
                v-for="(item, index) in videoData.video.thumbnails.filter(e => !e.includes('no-thumb'))"
                :key="index"
                :src="item"
                eager
                class="cursor-pointer bg-grey"
              >
              </v-carousel-item>
            </div>

            <v-carousel-item
              v-else
              eager
              class="cursor-pointer bg-grey"
            >
            </v-carousel-item>
          </div>
        </v-carousel>
      </v-card-text>

      <v-card-text>
        <v-item-group
          v-model="itemSelected"
          mandatory
        >
          <v-row>
            <v-col
              v-for="(item, i) in prices"
              :key="i"
              cols="4"
            >
              <v-item v-slot="{ active, toggle }">
                <v-card
                  class="rounded-lg"
                  :class="{'white-border': active}"
                  @click="toggle"
                >
                  <v-card-text class="pa-3 d-flex flex-column">
                    <span
                      class="text-body-1"
                      :class="{ 'white--text': active }"
                    >
                      {{ item.text }}
                    </span>
                    <span
                      :class="{ 'white--text': active && item.value > 0, 'success--text': item.value === 0 }"
                    >
                      {{ item.value > 0 ? formatCurrency(item.value) : $t('status.free') }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>

      <v-card-actions>
        <v-btn
          block
          color="secondary"
          type="submit"
          class="mt-8"
          style="height: 50px !important;"
          @click="() => videoData.video && videoData.video.hourly_price ? openUpdatePay(true) : onPay(null)"
        >
          <span
            v-if="videoData.video && videoData.video.hourly_price"
            style="text-transform: none !important;"
          >{{ capitalizateText(t('transactions.continue_to_paymen')) }} {{ videoData.video && videoData.video.hourly_price ? formatCurrency(selectedPrice) : '' }}</span>
          <span
            v-else
            style="text-transform: none !important;"
          >{{ capitalizateText(t('transactions.get_your_video')) }}</span>
        </v-btn>
      </v-card-actions>
    </div>

    <checkout-pay
      :is-open.sync="isDialogPay"
      :taxes="1"
      :subtotal="2"
      :total="selectedPrice"
      :payment-methods="paymentMethodsParams"
      :has-user="!!userData"
      :user-data="!!userData ? userData : false"
      @openClose="openUpdatePay"
      @save="onPay"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable no-lonely-if */
import {
  ref, computed, onMounted,
} from '@vue/composition-api'
import { addPayPremiumVideo } from '@api'
import {
  mdiVideoOutline,
  mdiVideoOffOutline,
  mdiCrownOutline,
} from '@mdi/js'
import 'video.js/dist/video-js.css'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency, isDev } from '@core/utils'
import { capitalizateText } from '@core/utils/filter'
import { success } from '@/@core/utils/toasted'
import moment from 'moment'
import themeConfig from '@themeConfig'
import useVideoData from '@core/utils/useVideoData'

import CheckoutPay from '@/views/video-checkout/components/CheckoutPay.vue'
import useCryptoJs from '@core/utils/useCryptoJs'

export default {
  components: {
    CheckoutPay,
  },
  setup() {
    const { t } = useUtils()
    const { router } = useRouter()
    const { videoData, currentVideoData, updateVideoData } = useVideoData()
    const { userData, decryptData, encrypt } = useCryptoJs()

    const isDialogPay = ref(false)
    const loginForm = ref(null)
    const itemSelected = ref(0)
    const paymentMethodsParams = ref([])

    const initPrices = ref([
      {
        value: 0,
        hour: 1,
        minutes: 60,
        text: '60 Min',
        show: true,
      },
      {
        value: 0,
        hour: 1.5,
        minutes: 90,
        text: '90 Min',
        show: true,
      },
      {
        value: 0,
        hour: 2,
        minutes: 120,
        text: '120 Min',
        show: true,
      },
      {
        value: 0,
        hour: 0.08,
        minutes: 5,
        text: '5 Min',
        show: isDev.value,
      },
    ])
    const prices = computed(() => initPrices.value.filter(e => e.show))
    const selectedPrice = computed(() => initPrices.value.at(itemSelected.value).value)
    const selectedTime = computed(() => initPrices.value.at(itemSelected.value).minutes)

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const payOrderVideo = async body => {
      const encryptInfo = encrypt(body)
      const respPay = await addPayPremiumVideo({ info: encryptInfo })
      if (respPay.ok) {
        success(respPay.message)
        const hash = respPay.data.share_video_url.split('/r/')[1]
        videoData.value.video.share_video_url = respPay.data.share_video_url
        await updateVideoData(videoData.value)
        router.replace({ name: 'recording', params: { hash } })
      }
    }

    const onPay = async cardInfo => {
      const body = {
        user_id: userData.value && userData.value.user_id ? userData.value.user_id : null,
        video_id: null,
        video_order_id: videoData.value.video ? videoData.value.video.video_order_id : null,
        video_time: selectedTime.value,
        card_info: cardInfo,
      }
      await payOrderVideo(body)
    }

    const formatVideoDay = videoDate => moment(`${videoDate}`, 'YYYY-MM-DD hh:mm').format('dddd HH:mm')
    const formatVideoMonth = videoDate => moment(`${videoDate}`, 'YYYY-MM-DD hh:mm').format('MMM DD')

    onMounted(() => {
      Object.assign(videoData.value, currentVideoData.value)
      if (videoData.value.video) {
        initPrices.value = initPrices.value.filter(e => {
          if (videoData.value.video.valid_minutes.includes(e.minutes) || e.minutes === 5) return true

          return false
        })

        initPrices.value = initPrices.value.map(e => ({
          ...e,
          value: e.hour * videoData.value.video.hourly_price,
        }))
      }

      if (videoData.value.paymentMethodsParams) {
        if (typeof (videoData.value.paymentMethodsParams) === 'string') paymentMethodsParams.value = decryptData(videoData.value.paymentMethodsParams)
        else paymentMethodsParams.value = videoData.value.paymentMethodsParams
      }

      const newPaymentMathod = {
        id: 0,
        last_4: null,
        exp_month: null,
        exp_year: null,
        brand: 'Add new card',
        exp_date_str: null,
      }
      if (paymentMethodsParams.value.legth) {
        const finded = paymentMethodsParams.value.find(e => e.id === 0)
        if (!finded) paymentMethodsParams.value.unshift(newPaymentMathod)
      } else paymentMethodsParams.value.unshift(newPaymentMathod)
    })

    return {
      // data
      userData,
      isDialogPay,
      loginForm,
      itemSelected,
      videoData,
      prices,
      selectedPrice,
      paymentMethodsParams,

      // methods
      formatCurrency,
      formatVideoDay,
      formatVideoMonth,
      openUpdatePay,
      onPay,
      capitalizateText,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      t,

      icons: {
        mdiVideoOutline,
        mdiVideoOffOutline,
        mdiCrownOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>
  .gold-border {
    border: 2px solid #FFCC00 !important;
  }

  .white-border {
    border: 2px solid #FFFFFF !important;
  }

  .bg-grey {
    background-color: #333333;
  }
</style>
